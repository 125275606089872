export var SearchList = [
  { labe: '联系方式', code: 'phone', type: 'input' },
  { labe: '姓名', code: 'name', type: 'input' }
]
export var SearchData = {
  phone: '',
  name: ''
}
export var tableField = [
  { label: '序号', type: 'function', width: '50px', handle: (index, data) => { return index + 1 } },
  { label: '姓名', code: 'name', type: 'input', width: '' },
  { label: '联系方式', code: 'phone', type: 'input', width: '' },
  { label: '部门', code: 'deptCode', type: 'input', width: '' },
  { label: '备注', code: 'typeName', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [{
      actionLabel: '编辑',
      id: '10'
    }, {
      actionLabel: '删除',
      id: '11'
    }]
  }
]
